<template>
  <section class="section">
    <div v-if="loaded" class="container">
      <!-- ITEMS -->
      <div class="colum">
        <div id="items" class="column is-6-tablet is-offset-3-tablet">
          <div class="show-archived is-flex is-justify-content-flex-end">
            <a href="#" @click.prevent="showArchived = !showArchived">{{ showArchived ? 'Hide archived' : 'Show archived' }}</a>
          </div>
          <!-- TODAY -->
          <div v-for="item in filteredItems" :key="item">
            <Item :item="item" v-if="showArchived || !item.archived" @archive="onArchive(item)" />
          </div>
        </div>
      </div>

      <!-- ADD BUTTON -->
      <router-link :to="{ name: 'Form', params: { id: 'new' } }">
        <div class="floating-btn">
          <span class="icon">
            <i class="fas fa-plus"></i>
          </span>
        </div>
      </router-link>

      <!-- END CONTAINER -->
    </div>
    <div v-else>Loading...</div>
  </section>
</template>

<script>
import { getItems, saveItem, deleteItem } from '../lib/items'
import Item from '@/components/Item'

export default {
  props: ['entity'],
  name: 'Home',
  components: {
    Item,
  },

  data() {
    return {
      items: [],
      loaded: false,
      showArchived: false,
    }
  },

  mounted() {
    this.load()
  },

  //------------------------
  // METHODS
  //------------------------

  methods: {
    async load() {
      this.items = await getItems(this.entity)
      this.loaded = true
    },

    //------------------------
    // HELPERS
    //------------------------

    //------------------------
    // LISTENERS
    //------------------------

    async onArchive(item) {
      if (item.archived) {
        item.archived = false
      } else {
        item.archived = true
      }
      await saveItem(item.id, item)
    },

    async onDelete(item) {
      await deleteItem(item.id)
      this.load()
    },
  },

  //------------------------
  // COMPUTED
  //------------------------

  computed: {
    filteredItems() {
      return this.items.sort((a, b) => {
        if (a.archived) {
          return 1
        }
        if (b.archived) {
          return -1
        }
        if (a.icon > b.icon) {
          return 1
        }
        if (a.icon < b.icon) {
          return -1
        }
        if (a.title > b.title) {
          return 1
        }
        if (a.title < b.title) {
          return -1
        }
        return 0
      })
    },
  },
}
</script>

<style scoped>
.floating-btn {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 20px;
  right: 20px;
  background-color: #5cb85c;
  color: #fff;
  border-radius: 100%;
  text-align: center;
  box-shadow: 2px 2px 3px #244724;
  display: flex;
  justify-content: center;
  align-items: center;
}

.floating-btn span {
  font-size: 22px;
}

.show-archived {
  margin-bottom: 10px;
}
</style>
