import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyAzAbiFoNZYBRkuHL3j5AzbP8g4wVzdqtI",
  authDomain: "tracker-d7da5.firebaseapp.com",
  projectId: "tracker-d7da5",
  storageBucket: "tracker-d7da5.appspot.com",
  messagingSenderId: "1029133388255",
  appId: "1:1029133388255:web:197954d096f4721e7a9c6a"
};

// init firebase
initializeApp(firebaseConfig)

// init services
const db = getFirestore()
const auth = getAuth()

export { db, auth }
