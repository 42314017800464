<template>
  <section class="section">
    <div class="container is-max-desktop">
      <div class="columns">
        <div class="column is-6-tablet is-offset-3-tablet">
          <div class="field">
            <input class="input" type="text" v-model="icon" placeholder="Icon" />
          </div>
          <div class="field">
            <input class="input" type="text" v-model="title" placeholder="Title" />
          </div>
          <div class="field">
            <input class="input" type="text" v-model="link" placeholder="Link" />
          </div>

          <!-- BUTTONS -->
          <div class="field is-grouped buttons">
            <div class="control">
              <a @click="onCancel" class="button is-link is-light">Cancel</a>
            </div>
            <div class="control" v-if="id != 'new'">
              <a @click="onDelete" class="button is-danger">Delete</a>
            </div>
            <div class="control">
              <button @click="onSubmit" class="button is-link">Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Links from '@/components/Links'
import { getItem, saveItem, createItem, deleteItem } from '../lib/items'
import { format, addDays } from 'date-fns'

export default {
  components: {
    Links,
  },

  props: ['id'],

  data() {
    return {
      icon: '',
      title: '',
      image: '',
      link: '',
      archived: false,
      week1: [],
      week2: [],
      daysSel: [],
    }
  },

  mounted() {
    this.load()
  },

  methods: {
    async load() {
      this.loadItem()
    },

    async loadItem() {
      const item = await getItem(this.id)

      if (item.exists()) {
        this.icon = item.data().icon
        this.title = item.data().title
        this.image = item.data().image
        this.link = item.data().link
        this.archived = item.data().archived ? item.data().archived : false
      }
    },

    async onSubmit() {
      const daysSorted = this.daysSel.sort((a, b) => {
        return b > a
      })

      const newItem = {
        icon: this.icon,
        title: this.title,
        link: this.link,
        archived: this.archived,
      }

      if (this.id == 'new') {
        await createItem(newItem)
      } else {
        await saveItem(this.id, newItem)
      }

      this.$router.push({ name: 'Home' })
    },

    onCancel() {
      this.$router.push({ name: 'Home' })
    },

    async onDelete() {
      await deleteItem(this.id)
      this.$router.push({ name: 'Home' })
    },
  },
}
</script>

<style scoped>
.week {
  margin-top: 20px;
}

.week-separator {
  margin: 5px;
  height: 20px;
  border-left: 1px solid white;
}

.day {
  cursor: pointer;
  height: 20px;
  width: 20px;
  margin: 5px;
  background-color: #3a536d;
  border-radius: 5px;
}

.day.selected {
  background-color: rgb(102, 190, 117);
}

.buttons {
  margin-top: 30px;
}

.btn-link {
  margin-top: 10px;
}
</style>
