<template>
  <div :class="['item', { archived: item.archived }]">
    <div class="is-flex is-align-items-center">
      <div class="is-flex-grow-1">
        <div class="is-flex is-align-items-center ">
          <div style="width: 30px; margin-right: 10px; padding-bottom: 5px;">
            <span style="font-size: 1.5rem;">{{ item.icon }}</span>
          </div>
          <router-link :to="{ name: 'Form', params: { id: item.id } }" class="navbar-item">
            <h1>{{ item.title }}</h1>
          </router-link>
          <a v-if="item.link" :href="item.link" target="_blank">
            <span class="icon btn-icon">
              <i class="fas fa-external-link-alt"></i>
            </span>
          </a>
        </div>
      </div>
      <div>
        <span class="icon btn-icon" @click="$emit('archive')">
          <i class="fas fa-archive"></i>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { format, addDays } from 'date-fns'

export default {
  props: ['item'],

  data() {
    return {
      week1: [],
      week2: [],
    }
  },

  mounted() {
    const today = new Date()
    const dayOfWeek = today.getDay() == 0 ? 7 : today.getDay()
    const nextSunday = addDays(today, 7 - dayOfWeek)
    for (let x = 0; x < dayOfWeek; x++) {
      const day = format(addDays(today, -x), 'yyyy-MM-dd')
      this.week1.push(day)
    }
    for (let x = 7; x < 14; x++) {
      const day = format(addDays(nextSunday, -x), 'yyyy-MM-dd')
      this.week2.push(day)
    }
  },
}
</script>

<style>
.item {
  background-color: #243444;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.item.archived {
  opacity: 0.5;
}

.item a {
  padding: 0;
}

.item h1 {
  font-size: 1.2rem;
}

.week-separator {
  margin: 0 5px;
  height: 10px;
  border-left: 1px solid white;
}

.days {
  margin-top: 10px;
}

.day {
  height: 10px;
  width: 10px;
  margin: 2px;
  background-color: #3a536d;
  border-radius: 2px;
}

.day.selected {
  background-color: rgb(102, 190, 117);
}

.btn-icon {
  opacity: 0.5;
  margin-left: 4px;
  cursor: pointer;
}

.btn-icon:hover {
  opacity: 0.7;
}
</style>
