import { db } from '../firebase/config'
import { doc, setDoc, addDoc, getDoc, collection, getDocs, orderBy, query, deleteDoc } from '@firebase/firestore'

export const getItems = async () => {
  const q = query(collection(db, 'items'), orderBy('title', 'asc'))
  const res = await getDocs(q)
  const items = res.docs.map((doc) => {
    return { id: doc.id, ...doc.data() }
  })
  return items
}

export const getItem = async (id) => {
  const docRef = doc(db, 'items', id)
  const comic = await getDoc(docRef)
  return comic
}

export const saveItem = async (id, item) => {
  await setDoc(doc(db, 'items', id), item)
}

export const createItem = async (item) => {
  await addDoc(collection(db, 'items'), item)
}

export const deleteItem = async (id) => {
  await deleteDoc(doc(db, 'items', id))
}
